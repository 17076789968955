import React from 'react';

import {SubHeading} from '../../components/index.js';
import images from '../../constants/images.js';

const FindUs = () => {
  return (
    <div className='app__wrapper app__bg section__padding'>
      <div className="app__wrapper_info">
        <SubHeading title='Contact' />
        <h1 className="headtext__cormorant">
          Find us
        </h1>
        <p className="p__opensans text-[var(--color-grey)] mt-[3rem] mb-[1rem] max650:mt-[2rem]">
        Lane Ends Bungalow, Whatcroft Hall Lane, Rudheath, CW9 7SG
        </p>
        <p className="p__cormorant text-[var(--color-golden)] mb-[1rem]">
        Opening Hours
        </p>
        <p className="p__opensans mb-[1rem]">Mon - Fri: 10:00 am - 02:00 am</p>
        <p className="p__opensans">Sat - Sun: 10:00 am - 03:00 am</p>
        <button type='button' className='custom__button bg-[var(--color-golden)] mt-[3rem]'>
            Visit Us
        </button>
      </div>
      <div className="app__wrapper_img app__wrapper_img_reverse">
        <img src={images.findus} alt="findus" className='w-full h-full' />
      </div>
    </div>
  )
}

export default FindUs