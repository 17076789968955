import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { images, data } from '../../constants';

const SpecialMenu = () => {
  return (
    <div className='bg-[var(--color-black)] flex-col flex__center section__padding' id='menu'>
      <SubHeading title='Menu that fits you palatte' style={'flex flex-col items-center'} />
      <h1 className='headtext__cormorant  mb-[2rem]'>Today’s Special</h1>


      <div className='flex w-full gap-[1rem] max1050:flex-col'>
        <div className='flex-1 flex flex-col items-center'>
          <h3 className='headtext__cormorant text-[45px] leading-[58.5px] text-[var(--color-white)] mb-[2rem] max650:text-[35px] max650:leading-[48.5px]'>Wine & Beer</h3>
          {
            data.wines.map((wine, i)=>(
              <MenuItem key={wine.title + i}
              title={wine.title}
              price={wine.price}
              tags={wine.tags}/>
            ))
          }
        </div>

        <div className='flex-1 2xl:w-[650px] max1050:my-[3rem]'>
          <img src={images.menu} alt="" className='w-full 3xl:h-[910px] 2xl:h-[710px]' />
        </div>

        <div className='flex-1 flex flex-col items-center'>
          <h3 className='headtext__cormorant text-[45px] leading-[58.5px] text-[var(--color-white)] mb-[2rem] max650:text-[35px] max650:leading-[48.5px]'>Cocktails</h3>
          {
            data.cocktails.map((cocktail, i)=>(
              <MenuItem key={cocktail.title + i}
              title={cocktail.title}
              price={cocktail.price}
              tags={cocktail.tags}/>
            ))
          }
        </div>
      </div>
      <div className='mt-8'>
        <button className='custom__button bg-[var(--color-golden)]'>View More</button>
      </div>
    </div>
  )
}

export default SpecialMenu