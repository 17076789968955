import React, { useRef, useState } from 'react';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';

import { meal } from '../../constants';


const Intro = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const vidRef = useRef();

  const handleVideo = () => {
    setPlayVideo((prev) => !prev)
    if (playVideo) {
      vidRef.current.pause();
    } else {
      vidRef.current.play();
    }
  }

  return (
    <div className='relative h-[100vh] max650:h-min flex__center app__bg'>
      <video
        className='h-[100%] w-[100%] object-cover max900:object-contain'
        ref={vidRef}
        src={meal}
        type='video/mp4'
        loop
        muted
        controls={false}
      />
      <div className={`absolute w-full h-full inset bg-[rgba(0,0,0,0.65)] flex__center`}>
        <div className=' border border-[var(--color-golden)] rounded-[50%] flex__center w-[120px] h-[120px] '
          onClick={handleVideo}>

          {
            playVideo ?
              <BsPauseFill fill='var(--color-white)' fontSize={30} />

              : 
              <BsFillPlayFill fill='var(--color-white)' fontSize={30} />

          }
        </div>
      </div>
    </div>
  )
}

export default Intro