import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const Chef = () => {
  return (
    <div className='app__bg section__padding app__wrapper'>
      <div className='app__wrapper_img app__wrapper_img-reverse'>
        <img src={images.chef} alt="chef" />
      </div>
      <div className='app__wrapper_info'>
        <SubHeading title='Chef’s Word' />
        <h1 className="headtext__cormorant">What we believe in</h1>
        <div className='flex flex-col my-[2rem]'>
          <div className='flex justify-start items-end'>
            <img src={images.quote} alt="quote sign" className='w-[47px] h-[40px]' />
            <p className="p__opensans ml-[5px] text-[var(--color-grey)] italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit auctor sit .
            </p>
          </div>
          <p className="p__opensans text-[var(--color-grey)] italic">auctor sit iaculis in arcu. Vulputate nulla lobortis mauris eget sit. Nulla scelerisque scelerisque congue ac consequat, aliquam molestie lectus eu. Congue iaculis integer curabitur semper sit nunc.</p>
        </div>
        <div>
          <p className="headtext__cormorant text-[32px] leading-[41.6px]">
            Kevin Luo
          </p>
          <p className="p__opensans text-[var(--color-grey)]">
            Chef & Founder
          </p>
        </div>
        <div className='w-[235px] h-[83px] mb-[3rem] xsm:w-[80%]'>
          <img src={images.sign} alt="" className='w-full h-auto mt-[3rem]'/>
        </div>
      </div>
    </div>
  )
}

export default Chef