import React from 'react';

import SubHeading from '../SubHeading/SubHeading';

const Newsletter = () => {
  return (
    <div className='z-[2] bg-[var(--color-black)] app__wrapper_info  items-center py-[2rem] px-[4rem] border-[var(--color-golden)] border-[0.6px] max650:px-[2rem]'>
      <SubHeading title='Newsletter' style={'flex flex-col items-center'} />
      <h1 className="headtext__cormorant text-center max300:text-[32px] max650:text-[50px] max650:leading-[60px]">
        Subscribe to our newsletter
      </h1>
      <p className="p__opensans mt-[1rem]">
        And never miss latest Updates!
      </p>
      <div className='w-full flex__center mt-[2rem] max990:flex-col'>
        <input type="email" className='w-[620px] self-stretch border-[var(--color-golden)] border-[0.6px] rounded-[5px] bg-transparent text-[1rem] text-[var(--font-base)] text-white mr-[2rem] px-[0.75rem] max990:w-full max990:py-[0.75rem] max990:mr-[0] 3xl:text-[2rem]' 
        placeholder='Enter your Email Address'/>
        <button type='button' className='custom__button bg-[var(--color-golden)] max990:mt-[2rem] '>Subscribe</button>
      </div>
    </div>
  )
}

export default Newsletter