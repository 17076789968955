import React from 'react';

import { SubHeading } from '../../components';
import { images, data } from '../../constants';

const Awards = ({ award: { imgUrl, title, subtitle } }) => {
  return (
    <div className='flex items-center  w-[49%] mb-[2rem] max900:w-full '>
      <img src={imgUrl} alt="rank" className='w-[49px] h-[49px]' />
      <div className='flex flex-col items-start  ml-[1rem]'>
        <p className='p__cormorant text-[var(--color-golden)]'>{title}</p>
        <p className='p__opensans text-[var(--color-grey)]'>{subtitle}</p>
      </div>
    </div>
  )
}

const Laurels = () => {
  return (
    <div className='app__wrapper section__padding app__bg max1150:flex-col' id='awards'>
      <div className='app__wrapper_info'>
        <SubHeading title={'Awards & recognition'} />
        <h1 className='headtext__cormorant'>Our Laurels</h1>
        <div className='flex flex-wrap w-full mt-[3rem] gap-[1%] max900:flex-col'>
          {
            data.awards.map((award, i) => (
              <Awards award={award} key={i} />
            ))
          }
        </div>
      </div>
      <div className='app__wrapper_img'>
        <img src={images.laurels} alt="" />
      </div>
    </div>
  )
}

export default Laurels