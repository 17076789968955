import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';


const Footer = () => {
  return (
    <div className='section__padding relative w-full flex flex-col justify-start bg-[var(--color-black)] pt-[0]'>
      <FooterOverlay />
      <Newsletter />

      <div className='w-full flex  mt-[5rem] justify-between z-[2] items-start max1150:flex-col'>
        <div className='flex-1 flex flex-col items-center justify-start m-4 max1150:w-full max1150:m-0 max1150:mb-[2rem]'>
          <p className="p__cormorant mt-[0.9rem]">Contact Us</p>
          <p className="p__opensans text-[var(--color-grey)] mt-[1rem]">9 W 53rd St, New York, NY 10019, USA</p>
          <p className="p__opensans text-[var(--color-grey)]">+1 212-344-1230</p>
          <p className="p__opensans text-[var(--color-grey)]">+1 212-555-1230</p>
        </div>


        <div className='flex-1 flex flex-col items-center justify-start m-4 max1150:w-full max1150:m-0  max1150:mb-[2rem]'>
          <img src={images.gericht} alt="logo" width='210px' />
          <p className="p__opensans mt-[2rem] text-center max1150:mt-[0.7rem] ">
            "The best way to find yourself is to lose yourself in the service of others.”
          </p>
          <img src={images.spoon} alt="spoon" className='spoon__img my-[1.5rem]' />
          <div className='flex'>
            <FiFacebook className='text-[#fff] text-[24px] cursor-pointer mx-[1rem] hover:text-[var(--color-golden)] hover:scale-[1.04]' />
            <FiTwitter className='text-[#fff] text-[24px] cursor-pointer mx-[1rem] hover:text-[var(--color-golden)] hover:scale-[1.04]' />
            <FiInstagram className='text-[#fff] text-[24px] cursor-pointer mx-[1rem] hover:text-[var(--color-golden)] hover:scale-[1.04]' />
          </div>
        </div>


        <div className='flex-1 flex flex-col items-center justify-start m-4 max1150:w-full max1150:m-0  max1150:mb-[2rem]'>
          <p className="p__cormorant mt-[0.9rem]">Working Hours</p>
          <div className='flex flex-col items-center justify-center mb-[0.6rem] mt-[1rem]'>
            <p className="p__opensans text-[var(--color-grey)]">Monday-Friday:</p>
            <p className="p__opensans text-[var(--color-grey)]">08:00 am -12:00 am</p>
          </div>
          <div className='flex flex-col items-center justify-center mb-[0.6rem]'>
            <p className="p__opensans text-[var(--color-grey)]">Saturday-Sunday:</p>
            <p className="p__opensans text-[var(--color-grey)]">07:00am -11:00 pm</p>
          </div>
        </div>
      </div>

      <div className='w-full flex items-center justify-center mt-[3rem] z-[2]'>
        <p className="p__opensans ">2021 Gerícht. All Rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer