import React from 'react';

import images from '../../constants/images';


const SubHeading = ({title, style}) => {
  return (
    <div className={`mb-[1rem] ${style}`}>
      <p className="p__cormorant">
        {title}
      </p>
      <img src={images.spoon} alt="spoon" className='spoon__img mt-4' />
    </div>
  )
}

export default SubHeading