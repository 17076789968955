import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';

import images from '../../constants/images.js';
import './Navbar.css';

const Navbar = () => {
  const [menu, setMenu] = useState(false)


  return (
    <nav className='w-full flex items-center justify-between py-4 px-8'
      style={{ background: 'var(--color-black)' }}>
      <div className='flex justify-start items-center'>
        <img src={images.gericht} alt="app logo" className='w-[199px] h-[57px] 2xl:w-[210px] sm:w-[150px] sm:h-[42px] msm:w-[100px] msm:h-[30px]
        xsm:w-[70px] xsm:h-[22px]' />
      </div>
      <ul className={`app__navbar-links flex-1 lg:flex
      xl:flex 2xl:flex justify-center items-center hidden`}>
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#pages">Pages</a></li>
        <li className="p__opensans"><a href="#contactUs">Contact Us</a></li>
        <li className="p__opensans"><a href="#blog">Blog</a></li>
        <li className="p__opensans"><a href="#landing">Landing</a></li>
      </ul>

      <div className="app__navbar-login flex justify-end items-center xsm:hidden">
        <a href="#login" className='p__opensans'>Log In / Register</a>
        <div className='w-0 h-[30px] xsm:h-[20px]'
          style={{
            border: '1px solid var(--color-golden)',
          }} />
        <a href="/" className='p__opensans'>Book Table</a>
      </div>

      {/* Hamburger Menu for smaller screen */}
      <div className="app__navbar-menu  block lg:hidden xl:hidden 2xl:hidden">
        <GiHamburgerMenu color='#fff' fontSize={27}
          onClick={() => setMenu(true)} className={`${menu ? 'hidden' : 'block'} msm:text-[20px]`} />

        {
          menu && (
            <div
              className={` fixed w-screen h-screen
          top-0 left-0 flex items-center justify-center
          slide-in-blurred-top z-5
          ${!menu && 'slide-out-blurred-top'}
        `}
              style={{
                background: 'var(--color-black)'
              }}>
              <MdOutlineRestaurantMenu color='var(--color-golden)' onClick={() => setMenu(false)} fontSize={27}
                className='absolute top-7 right-7' />
              <ul className='navbar__openMenu-links'>
                <li className="p__opensans"><a href="#home">Home</a></li>
                <li className="p__opensans"><a href="#pages">Pages</a></li>
                <li className="p__opensans"><a href="#contactUs">Contact Us</a></li>
                <li className="p__opensans"><a href="#blog">Blog</a></li>
                <li className="p__opensans"><a href="#landing">Landing</a></li>
              </ul>
            </div>
          )
        }

      </div>
    </nav>
  )
}

export default Navbar