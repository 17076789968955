import React from 'react'

const MenuItem = ({ title, price, tags }) => {
  return (
    <div className='flex flex-col  w-full mb-8'>
      <div className='w-full flex  items-center'>
        <div className='flex-[2.5]'>
          <p className='p__cormorant text-[var(--color-golden)]'>{title}</p>
        </div>
        <div className='flex-1 border h-0' />
        <p className='flex-[0.8] text-[var(--color-white)] text-end'>{price}</p>
      </div>
      <div className='flex w-full items-center justify-start'>
        {
          tags.map((charachter, index) => {
            return (
              <React.Fragment key={charachter + index}>
                <p className='p__opensans text-[var(--color-grey)]'>{charachter}</p>
                {
                  index !== tags.length - 1 && (
                    <div className='h-4 w-0 border mx-3 border-[var(--color-grey)]' />
                  )
                }
              </React.Fragment>
            )

          }
          )
        }
      </div>
    </div>
  )
}

export default MenuItem