import React, { useRef } from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';


const Gallery = () => {
  const galleryImages = [images.gallery01, images.gallery02, images.gallery03, images.gallery04];

  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if(direction === 'left'){
      scrollRef.current.scrollLeft -= 300;
    }else{
      scrollRef.current.scrollLeft += 300;
    }
  }

  return (
    <div className='flex__center bg-[var(--color-black)] py-[4rem] pl-[6rem] pr-[0] max650:pl-[2rem] sm:pl-[4rem]  max1150:flex-col'>
      <div className="flex flex-1 flex-col justify-center items-start min-w-[500px] pr-[2rem] max650:min-w-full">
        <SubHeading title={'Instagram'} />
        <h1 className="headtext__cormorant text-[var(--color-golden)] my-[2rem]">
          Photo Gallery
        </h1>
        <p className="p__opensans text-[var(--color-grey)]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisque egestas mu.
        </p>
        <button type='button' className="custom__button mt-[2rem] bg-[var(--color-golden)]">
          View More
        </button>
      </div>

      <div className='flex-1 flex relative max-w-[50%] max1150:max-w-full max1150:my-[5rem]'>
        <div ref={scrollRef}  className='app__gallery-images_container flex w-max overflow-x-scroll'>
          {
            galleryImages.map((image, i) => (
              <div key={`gallery_image-${i + 1}`} className='app__gallery-images_card relative min-w-[301px] h-[447px] mr-[2rem] flex items-center justify-center max650:min-w-[240px] max650:h-[320px]'>
                  <img src={image} alt="gallery image" className='w-full h-full object-cover opacity-[1]  duration-[0.5s] ease'/>
                  <BsInstagram className='absolute text-[#fff] text-[2rem] opacity-[0.5] cursor-pointer hover:scale-[1.05] transition-all duration-[0.5s] ease'/>
              </div>
            ))
          }
        </div>
        <div  className='w-full flex justify-between items-center px-[1rem] absolute bottom-[5%] translate-y-[-50%]'>
          <BsArrowLeftShort onClick={()=>scroll('left')} className='text-[32px] text-[var(--color-golden)] cursor-pointer bg-[var(--color-black)] opacity-[0.8] hover:opacity-[1] scale-[1] hover:scale-[0.82] transition-scale duration-[0.3s] ease hover:text-[#fff]'/>
          <BsArrowRightShort onClick={()=>scroll('right')} className='text-[32px] text-[var(--color-golden)] cursor-pointer bg-[var(--color-black)] opacity-[0.8] hover:opacity-[1] scale-[1] hover:scale-[0.82] transition-scale duration-[0.3s] ease hover:text-[#fff]'/>
        </div>
      </div>

    </div>
  )
}

export default Gallery