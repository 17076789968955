import React from 'react'

const FooterOverlay = () => {
  return (
    <div className='w-full h-full z-[1] flex flex-col absolute top-0 left-0'>
      <div className='h-[25%] bg-[var(--color-black)]'/>
      <div className='h-[75%] app__bg'/>
    </div>
  )
}

export default FooterOverlay