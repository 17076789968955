import React from 'react'

import { images } from '../../constants'
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className='app__bg flex__center section__padding relative' id='about'
    >

      <div className='flex__center z-[0] w-[391px] h-[415px] absolute max650:w-[300px] max650:h-[320px]'>
        <img src={images.G} alt="g letter" />
      </div>

      <div className="flex__center z-[1] w-full max900:flex-col">


        <div className="flex flex-col items-end">
          <h1 className='headtext__cormorant max1024:text-[58px]'>About Us</h1>
          <img src={images.spoon} alt="about_spoon" className='spoon__img scale-x-[-1]' />
          <p className="p__opensans text-end my-[2rem] text-[var(--color-grey)]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis pharetra adipiscing ultrices vulputate posuere tristique. In sed odio nec aliquet eu proin mauris et.
          </p>
          <button type='button' className='custom__button bg-[var(--color-golden)] '>Know More</button>
        </div>

        <div className="flex__center  mx-[4rem] my-[2rem] max900:my-[4rem] max900:mx-[0]">
          <img src={images.knife} alt="" className='w-full h-full max650:h-[320px] ' />
        </div>

        <div className="flex flex-col items-start">
          <h1 className='headtext__cormorant max1024:text-[58px]'>Our History </h1>
          <img src={images.spoon} alt="about_spoon" className='spoon__img' />
          <p className="p__opensans text-start my-[2rem] text-[var(--color-grey)]">
            Adipiscing tempus ullamcorper lobortis odio tellus arcu volutpat. Risus placerat morbi volutpat habitasse interdum mi aliquam In sed odio nec aliquet.
          </p>
          <button type='button' className='custom__button bg-[var(--color-golden)] '>Know More</button>


        </div>
      </div>


    </div>
  )
}

export default AboutUs