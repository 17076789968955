import React from 'react';

import { SubHeading } from '../../components';
import images from '../../constants/images';
import './Header.css';

const Header = () => {
  return (
    <div className='app__wrapper section__padding bg-[var(--color-black)]'>

      <div className="app__wrapper_info ">
        <SubHeading title={'Chase The New Flavour'}/>
        <h1 className='headtext__cormorant text-[90px] leading-[117px]'>The key to Fine dining</h1>
        <p className="p__opensans text-[#AAA] my-[2rem]">
          Sit tellus lobortis sed senectus vivamus molestie. Condimentum volutpat morbi facilisis quam scelerisque sapien. Et, penatibus aliquam amet tellus
        </p>
          <button type='button' className="custom__button bg-[var(--color-golden)]">Explore Menu</button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.welcome} alt="" />
      </div>

    </div>
  )
}

export default Header